import React, { useMemo } from 'react';

import { Button as MuiButton } from '@mui/material';

import Styles from '@/shared/styles';

interface PropsType {
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'secondary' | 'success' | 'error' | 'info';
  type?: 'submit' | 'button' | 'reset' | undefined;
  width?: number;
  height?: number;
  text: string | JSX.Element;
  fontSize?: string;
  fontWeight?: string;
  marginLeft?: number;
  marginRight?: number;
  padding?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: (e?: any) => void;
  borderWidth?: number;
  borderColor?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  background?: string;
}

const Button: React.FC<PropsType> = ({
  type,
  variant,
  width,
  height,
  text,
  fontSize,
  color,
  fontWeight,
  marginLeft,
  marginRight,
  padding,
  onClick,
  borderWidth,
  borderColor,
  startIcon,
  endIcon,
  disabled,
  style,
  background,
}) => {
  const getColor = useMemo(() => {
    if (disabled) return '';
    if (color === 'secondary') return Styles.color.logoYellow;
    if (color === 'success') return Styles.color.logoGreen;
    if (color === 'error') return Styles.color.red;
    if ((!color || color === 'info') && borderColor) return borderColor;
    if (color === 'info') return Styles.color.blue;
    return '';
  }, [color, borderColor, disabled]);

  const getBorderColor = useMemo(() => {
    if (disabled) return '';
    return borderColor;
  }, [borderColor, disabled]);

  const getBackground = useMemo(() => {
    if (disabled) return '';
    if (background === 'check')
      return 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 50%, #0D6FBA 50%, #0D6FBA 100%)';
    if (color === 'secondary') return Styles.color.logoYellow;
    if (color === 'success') return Styles.color.logoGreen;
    if (color === 'error') return Styles.color.red;
    if (color === 'info') return Styles.color.white;
    return '';
  }, [color, background, disabled]);

  return (
    <MuiButton
      type={type || 'button'}
      variant={variant || 'contained'}
      style={{
        background: getBackground,
        fontWeight: fontWeight || '400',
        fontSize: fontSize || Styles.default.buttonFontSize,
        width: `${width || 120}px`,
        height: `${height || 30}px`,
        lineHeight: `${height || 30}px`,
        marginLeft: `${marginLeft || 0}px`,
        marginRight: `${marginRight || 0}px`,
        borderWidth: `${borderWidth || 1}px`,
        borderColor: getBorderColor || getColor,
        color: variant === 'contained' || !variant ? Styles.color.white : getColor,
        padding: padding || '0',
        ...style,
      }}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled || false}
    >
      {text}
    </MuiButton>
  );
};

export default Button;
