import styled from '@emotion/styled';

const white = 'white';
const black = '#333333';
const whiteGrey = '#f5f5f5';
const grey = '#cccccc';
const dimGray = '#696969';
const blue = '#0D6FBA';
const red = '#f0340c';
const skyBlue = '#7fbfff';
const chineseSilver = '#cccccc';
const loadingBackground = 'rgba(255, 255, 255, 0.7)';
const logoBlue = '#1976d2';
const logoGreen = '#40ac94';
const logoYellow = '#fcb40c';
const muiTableBorderGrey = `rgba(224, 224, 224, 1)`;

const attentionHigh = '#f0340c';
const attentionMiddle = '#ffa500';
const attentionLow = '#11AC97';

const llmAttentionLevelHighAndNotLlm = '#151B23';
const llmAttentionLevelMiddle = '#5D5F5F';
const llmAttentionLevelLow = '#B7B7B7';

const cautionOne = '#991E00';
const cautionTwo = '#997000';
const cautionThree = '#004D43';
const cautionFour = '#5D5F5F';

const decisionApprove = '#00AC97';
const decisionDisApprove = '#E8380D';
const decisionPending = '#5D5F5F';

const defaultValue = {
  fontFamily: '"Hiragino Kaku Gothic ProN", sans-serif',
  fontSize: '1.3rem',
  buttonFontSize: '1.5rem',
  hover: {
    opacity: 0.8,
  },
};

export default {
  color: {
    white,
    black,
    blue,
    red,
    skyBlue,
    dimGray,
    grey,
    whiteGrey,
    borderLine: grey,
    navigationBG: white,
    topHeaderBG: white,
    bodyBG: whiteGrey,
    footerBG: white,
    mui5Border: chineseSilver,
    loadingBackground,
    logoBlue,
    logoGreen,
    logoYellow,
    muiTableBorderGrey,
    attentionHigh,
    attentionMiddle,
    attentionLow,
    riskLevelHigh: attentionHigh,
    riskLevelMiddle: attentionMiddle,
    riskLevelLow: attentionLow,
    riskLevelNone: '#B0C4DE',
    llmRiskLevelHighAndNotLlm: llmAttentionLevelHighAndNotLlm,
    llmRiskLevelMiddle: llmAttentionLevelMiddle,
    llmRiskLevelLow: llmAttentionLevelLow,
    checkDecisionChoiceNone: logoGreen,
    checkDecisionChoiceApprove: logoGreen,
    checkDecisionChoiceDisapprove: red,
    checkDecisionChoicePending: dimGray,
    cautionClassificationOne: cautionOne,
    cautionClassificationTwo: cautionTwo,
    cautionClassificationThree: cautionThree,
    cautionClassificationFour: cautionFour,
    checkDecisionGraphApprove: decisionApprove,
    checkDecisionGraphDisApprove: decisionDisApprove,
    checkDecisionGraphPending: decisionPending,
  },
  border: {
    normal: `1px solid ${black}`,
    error: `2px solid ${red}`,
  },
  boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
  0px 1px 5px 0px rgb(0 0 0 / 12%)`,
  default: defaultValue,
};

type FontDivProps = {
  fontSize?: string;
  fontWeight?: string;
  width: string;
};

export const FontDiv = styled.div<FontDivProps>`
  font-size: ${({ fontSize }) => fontSize || defaultValue.fontSize};
  width: ${({ width }) => width};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;
